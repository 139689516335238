.App-banner{
  height: 100vh;
  max-height: 360px;
}
.App-blog-card{
  background: #fff;
  box-shadow: 0 0.1875rem 1.5rem rgb(0 0 0 / 20%);
  border-radius: 0.375rem;
  overflow: hidden;
  flex: 1;
}
.post-image {
  transition: opacity 0.3s ease;
  display: block;
  width: 100%;
  object-fit: cover;
}
.article-details {
  padding: 1.5rem;
  flex: 1;
}
.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid #ebebeb;
  width: max-content;
}
.post-title {
  transition: color 0.3s ease;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #121212;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  flex: 1;
}
.post-description{
  font-size: 15px;
  color: #777;
}
.post-author {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid #ebebeb;
}

.post-img-wrap{
  padding-top: 65%;
}
.post-img-wrap img{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}